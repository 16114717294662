.go-back-button-wrapper {
  position: absolute !important;
  z-index: 1 !important;
  margin-top: 4vh !important;
  margin-left: 1vw !important;
}
.go-back-button-style {
  padding: 15px;
  color: white !important;
  background-color: #4357a18a;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 0px #14243a65;
}

.go-back-button-style:hover {
  transition: 30s fade-in !important;
  background-color: #4357a1b0 !important;
}

.position-on-document.go-back-button-wrapper {
  position: absolute !important;
  z-index: 1 !important;
  margin-top: 0.7rem !important;
  margin-left: 1vw !important;
}

@media only screen and (max-width: 736px) {
  .go-back-button-style {
    padding: 5px;
    color: #4357a1b7 !important;
    background: none !important;
    border-radius: 5px !important;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    box-shadow: none !important;
  }
  .go-back-button-style:hover {
    padding: 15px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: 0.3s ease-in-out !important;
    color: white !important;
    background-color: #4357a18a !important;
    border-radius: 10px !important;
    box-shadow: 0px 2px 5px 0px #14243a91 !important;
  }
}
