.mantine-Popover-dropdown {
  z-index: 9999 !important;
}

/* .PdfHighlighter {
  position: relative;
  min-width: 400px !important;
  max-height: 40vmax !important;
  overflow: unset !important;
} */

.pdf-previewer {
  overflow: scroll;
  max-height: 40vmax;
  margin: auto;
}

.pdf-previewer-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: auto;
  overflow: auto;
  height: calc(100% - 65px);
}

#pdfModal .modal-content {
  height: calc(100vh - 60px);
  /* overflow-y: scroll; */
}

.pdfModalButton {
  background-color: #35447eec;
  color: white !important;
  padding: 5px !important;
  border-radius: 5px !important;
  border: none !important;
  box-shadow: 0px 2px 5px 0px #14243a !important;
}

a.pdfModalButton {
  display: block;
}

.pdf-previewer-inner-wrapper {
  /* max-height: 40vmax;
  min-height: 33vmax; */
  height: 100%;
  width: 100%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: auto;
}

.sidebar {
  overflow: auto;
  color: rgb(119, 119, 119);
  /* background: linear-gradient(
    rgb(249, 248, 247),
    rgb(251, 250, 248) 46px,
    rgb(251, 251, 249) 120px,
    rgb(248, 247, 245) 35%,
    rgb(249, 248, 246)
  ); */
  position: relative;
  min-width: 400px;
  /* width: 85vw; */
}

.sidebar__highlights {
  list-style: 'none';
  padding: 0;
}

.highlight__location {
  margin-top: 0.5rem;
  /* text-align: right; */
  font-size: 10px;
}

.highlight__image {
  overflow: auto;
  max-width: 300px;
  border: 1px dashed;
}

.sidebar__highlight {
  padding: 1rem;
  cursor: pointer;
  transition: background 140ms ease-in;
  border-bottom: 1px solid rgb(119, 119, 119);
}

.sidebar__highlight:hover {
  background: #2f438a1a;
}

.selected__sidebar__highlight {
  background: #2f438a1a;
  animation: changeBackground 5s forwards;
  animation-delay: 3s;
}

@keyframes changeBackground {
  0% {
    background: #2f438a1a;
    /* Inicijalna boja */
  }

  100% {
    background: white;
    /* Nova boja pozadine nakon animacije */
  }
}

/* a {
  color: #d35400;
} */

blockquote {
  padding: 0;
  margin: 0;
  quotes: '\201c' '\201d';
}

blockquote:before {
  content: open-quote;
}

blockquote:after {
  content: close-quote;
}

/* @media only screen and (max-width: 917px) {
  .pdf-previewer-wrapper {
    display: flex;
    flex-direction: column !important;
  }
} */

.Highlight__popup {
  min-width: 30vw !important;
  min-height: 200px !important;
  height: 100% !important;
}

.Tip__compact,
.Highlight__popup {
  background-color: #2f456d !important;
}


.Tip__card textarea {
  min-width: 40vw !important;
  min-height: 150px !important;
  width: 100% !important;
  /* height: auto !important; */
}



.Tip__card {
  display: flex;
  flex-flow: column wrap;
  min-width: 40vw !important;
  min-height: 150px !important;
  /* box-shadow: 0px 5px 15px 3px #14243a91 !important; */
  margin-top: 20px !important;
  position: relative;
}



.show-info-button-wrapper,
.show-info-button-wrapper-open {
  position: absolute !important;
  z-index: 1;
  right: 0;
  margin-top: 0.7rem !important;
  margin-right: 0.1vw;
}

.show-info-button-wrapper-open {
  z-index: 4;
  margin-bottom: 50vh !important;
}

.show-info-button-style,
.show-info-button-style-specific-comment {
  padding: 15px;
  color: white !important;
  background-color: #35447eec;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 0px #14243a65;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.show-info-button-style-specific-comment {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 3px 5px 0px #14243ab4 !important;
}

.show-info-button-style:hover,
.show-info-button-style-specific-comment:hover {
  transition: 30s fade-in right !important;
  background-color: #4357a1b0 !important;
}

.show-info-content-style {
  /* opacity: 1; */
  position: absolute;
  z-index: 9;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  padding: 15px;
  color: white !important;
  background-color: #35447eec;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 0px #14243a65;
  width: 400px !important;
  right: 20px;
}

.show-info-content-style:hover {
  transition: 30s fade-in right !important;
  background-color: #35447efd;
}

.tip-wrapper {
  background-color: white;
  min-width: 100%;
  max-height: 50vh;
  box-shadow: 0px 5px 15px 3px #14243a91;
  padding: 3vmin;
  /* position: relative !important; */
  vertical-align: middle !important;
  z-index: 999999999999999999999999999999999999999999 !important;
  overflow-y: auto;
}

.PdfHighlighter__tip-container:has(form) {
  left: 50% !important;
  transform: translateX(-50%) !important;
}

@media only screen and (max-width: 736px) {
  .show-info-button-style {
    padding: 15px;
    color: #4357a1b7 !important;
    background: #35447ef6 !important;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 0px #14243a65;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }

  .show-info-button-style:hover {
    padding: 15px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: 0.3s ease-in-out !important;
    color: white !important;
    background-color: #35447eec !important;
    border-radius: 10px !important;
    box-shadow: 0px 2px 5px 0px #14243a91 !important;
  }

  .tip-wrapper {
    background-color: white;
    min-width: 100%;
    max-height: 300px;
    box-shadow: 0px 5px 15px 3px #14243a91;
    padding: 3vmin;
    position: relative !important;
    vertical-align: middle !important;
    z-index: 10000000000000;
    overflow-y: auto;
  }
}

.document-button-position-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center !important;
  align-content: center !important;
  align-items: center !important;
  height: 65px;
  border-bottom: 1px solid #dee2e6;
  padding-left: 10px;
  padding-right: 10px;
}

.pdfModalButton .white-link-text {
  color: white !important;
}


@media only screen and (max-width: 736px) {
  .document-button-position-wrapper {
    display: flex;
    flex-flow: column wrap !important;
    justify-content: flex-start !important;
    align-items: center !important;
    align-content: center !important;
  }

  /* .document-button-position-wrapper > * {
    padding-bottom: 10px !important;
    padding-left: 50px !important;
  }
  .document-button-position-wrapper.m-auto {
    margin: 0 !important;
  } */
}