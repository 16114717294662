.info-card {
  border-radius: 10px;
  min-height: 12vh;
  box-shadow: 1px 3px 10px 0 #c9c9fd;
}

.logoXLG {
  height: calc(15vh + 6vmin);
}

#statsWrapper {
  min-height: 25rem;
  display: flex;
  flex-flow: column wrap;
  justify-content: baseline;
  align-content: center;
}

.counter-wrapper {
  background: #50679b;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-content: center;
}
.counter-wrapper:hover {
  background: #50679bc9;
}

.counter-style {
  font-size: calc(1% + 20px);
  color: white;
}

.stats-combo-style,
.stats-combo-style:focus-within {
  border: solid 1px rgb(168, 187, 206) !important;
}
