.sort-icon {

}
.sort-icon:hover {
    cursor: pointer;
}

.comment-filter-header, .comment-display-pagination {
    background-color: #243964;
    color: white;
    padding: 10px;
    /* border-top-left-radius: 5px;
    border-top-right-radius: 5px; */
}

.comment-date-picker > div > button {
    border-radius: 0px;
    border: 0;
    border-bottom: 1px solid white;
    background-color: transparent;
    color: white;
}
.comment-filter-input {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid white;
    color: white;
    padding-left: 8px;
}
.comment-filter-input:focus {
    outline: 0;
}
.comment-filter-input::placeholder{
    color: white;
    opacity: 0.6;
    font-size: 0.9em;
}
.truncated {
    -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
    height:  50px;
    margin-bottom: 0px;
}
.user-offset {
    margin-left: 55px;
}

.emotion { 
    color: #243964;
    
}
.can-like {
    cursor: pointer;
}

.attachment-badge {
    cursor: pointer;
}

.pointer{
    cursor: pointer;
}

.filter-select > div {
    background-color: #243964 !important;
    border: 0 !important;
    border-radius: 0 !important;
    border-bottom: 1px solid white !important;
    padding: 0 !important;
    box-shadow: unset !important;
    min-height: unset;
    min-width: unset;

}


.select-page-size option {
color: blue; /* Change this to your desired color */
}


