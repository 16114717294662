.editButton {
  font-size: 24px;
}

.editButton:hover,
.deleteButton:hover,
.previewButton:hover,
.uploadButton:hover,
.createButton:hover,
.editButton-global:hover,
.nonempty-list-button:hover {
  color: #50679b;
}

.deleteButton,
.previewButton,
.editButton,
.uploadButton,
.createButton,
.nonempty-list-button,
.editButton-global {
  color: #243964;
  cursor: pointer !important;
}

.deleteButton,
.previewButton {
  font-size: 18px;
}

.createButton {
  font-size: 27px;
}

.editButton-global {
  font-size: 24px;
}

#addTopicButton {
  font-size: 24px !important;
}

.input-file-button {
  display: inline-block;
  cursor: pointer;
  overflow: hidden;
  background-color: #3c5097 !important;
  border-radius: 10px;
  padding: 8px !important;
  min-height: max-content !important;
  border: none;
  color: #fff;
  display: inline-block;
  text-align: center;
  max-height: 65px;
  font-size: 17px;
}

.input-file-button:hover {
  background-color: #384a8b !important;
  font-size: 17.5px;
  padding: 7px !important;
}

#subscribeButton,
.create-button,
.Tip__card input {
  background-color: #203a5d;
  color: white !important;
  border: none !important;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0px #14243a;
  margin-right: 1em;
}

.create-button-group {
  width: 200px;
  display: inline-block;
}

.subscribe-button-grid {
  margin-right: 0;
}

.btn-mr {
  margin-right: 1em;
}

.create-button {
  min-width: 90px !important;
}

.create-label-text {
  font-size: 18px !important;
}

.add-button-width {
  min-width: 8vw !important;
}

#subscribeButton:hover,
.create-button:hover,
.Tip__card input:hover {
  background-color: #28476d;
  /* background-color: #2e3b6b; */
}

#declineButton {
  background-color: rgb(124, 124, 124);
  color: white !important;
  border: none !important;
  padding: 8px;
}

#declineButton:hover,
.unsubscribe-button {
  background-color: rgb(145, 145, 145);
}

.unsubscribe-button {
  background-color: rgb(124, 124, 124);
  color: white !important;
  border: none !important;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0px #353535;
  padding-left: 12px;
  padding-right: 12px;
}

.scroll-button-style {
  border: none !important;
  outline: none !important;
  background-color: #4357a1c4 !important;
  border-radius: 10px !important;
  box-shadow: 0px 2px 5px 0px #14243a91;
  width: 50px !important;
  height: 50px !important;
  z-index: 999 !important;
}

.scroll-button-style:hover {
  background-color: #4357a1f3 !important;
}

.Tip__card input {
  float: right !important;
}

.vote-icon-default-style {
  color: grey;
}

.empty-list-button,
.vote-icon-default-style:hover {
  color: #6c7694 !important;
  cursor: pointer;
}

.empty-list-button:hover {
  color: #536191 !important;
}

.save-version-button-style {
  padding: 13px;
  color: white !important;
  background-color: #35447eec;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 0px #14243a65;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.save-version-button-style:hover {
  transition: 30s fade-in right !important;
  background-color: #3c4d8bde !important;
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

.only-icon {
  border: 0;
  background-color: transparent;
  color: #203a5d;
}

.radioButtonForm {
  display: flex;
  justify-content: space-between;
  gap: 7px;
  margin-bottom: 10px !important;
  /* border: 1px solid #0000001f; */
  width: fit-content !important;
  height: 40px;
  font-size: 14px;
  background: #00000006;
  border-radius: 5px;
  box-shadow: 1px 3px 10px 1px rgb(0 0 0 / 8%);
  margin-bottom: 1rem;
  margin: auto;
}

.radioButtonForm input {
  width: 18px;
  height: 18px;
}

.radioButtonForm input:checked {
  width: 20px;
  height: 20px;
}

.radioButtonForm>div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.noBorder {
  border: 0;
  background: transparent;
}

.btnDownloadDocument {
  border: 0;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.747);
}

.btnDownloadDocument:hover {
  color: black;
}

.btnDownloadDocument i {
  font-size: 20px;
}

#pdf-download {
  display: none;
}

.viewPdfDownload {
  margin-top: 0 !important;
  left: 20px;
}

.noMargin {
  margin: 0;
}

.create-question {
  height: 26px;
  padding: 0px;
}

.tip-close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
}

@media screen and (max-width: 760px) {
  .create-button-group {
    width: unset;
    display: unset;
  }
}