@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;1,400&display=swap');

#root {
  font-family: 'Montserrat' !important;
  font-size: calc(1% + 15px);
  font-weight: 400;
  display: block;
  min-width: 100% !important;
  min-height: 100% !important;
  position: relative;
  -webkit-box-pack: justify;
}

.form-control {
  outline: none !important;
  box-shadow: none !important;
}

.content-min-height {
  min-height: 80vh !important;
  /* max-width: 1366px !important;
  position: relative; */
}

.bottom-border {
  border-bottom: 1px solid rgba(204, 204, 204, 0.63);
}

:any-link,
a {
  text-decoration: none !important;
}

.textShadow {
  /* text-shadow: 0 0 1px rgba(0, 0, 0, 0.452); */
  text-shadow: 0 0 0.8px rgb(14, 14, 14);
}

.redAsterisk {
  color: red;
  text-decoration: none !important;
}

#logo {
  min-height: calc(37px + 1vmin);
  height: calc(37px + 2vmin);
  max-width: 340px !important;
}

#topicUnorderedList {
  list-style: none;
}

#topicUnorderedList li::before {
  content: '\2022';
  color: #ffffff73;
  font-weight: bold;
  display: flex;
  min-width: 1em;
}

li {
  display: flex;
}

.bullet-char {
  color: #ffffff57;
  font-weight: bold;
  margin-right: 0.5em;
}

ol li {
  display: list-item;
  list-style-position: outside;
}

#small-margin-left,
.bullet-char {
  margin-left: 0.5em;
}

.white-link-text {
  color: white !important;
}

/* .white-link-text:hover {
  color: #778baf !important;
} */

.boldText {
  font-weight: bold;
}

.weight500 {
  font-weight: 500;
}

.footerText {
  color: #697c9d !important;
}

#footerMainLogoText {
  font-size: calc(1.7vmin + 3px);
  color: #96949efa;
}

#footerMainLogoEmText {
  font-style: normal;
  color: #203a5dda;
  font-weight: bold;
}



.logoLG {
  min-height: calc(55px + 1vmin);
  height: calc(57px + 2vmin);
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.dateInput {
  width: 100% !important;
  background-color: white !important;
}

.emphesized-label-font {
  font-size: calc(1% + 16px) !important;
}

.default-label-text {
  color: #374e82 !important;
}

#inputFileRemoveDefault [type='file'] {
  display: none;
}

.uploadFile-card {
  display: flex;
  flex-flow: row wrap !important;
  justify-content: center;
  border-radius: 40px;
  box-shadow: 1px 3px 10px 0 #c9c9fd;
  min-height: 70vh !important;
  background-color: rgb(255, 255, 255);
  text-align: center;
  width: 65% !important;
  padding: 1%;
}

.fileName {
  margin-top: 1.5vh;
  margin-bottom: -3vh !important;
}

.remove-link-effect {
  color: inherit !important;
}

.remove-link-effect:hover {
  color: inherit !important;
}

.small-font {
  font-size: calc(1% + 12px);
}

.medium-font {
  font-size: calc(1% + 13.3px);
}

.large-font {
  font-size: calc(1% + 18px);
}

.regular-font {
  font-size: calc(1% + 16px);
}

.faded-text {
  color: rgba(71, 71, 71, 0.712) !important;
}

.custom-control-input:hover,
.custom-control-label:hover {
  cursor: pointer !important;
}

.custom-control-input:checked~.custom-control-label::before {
  background-color: #374e82 !important;
  outline: none !important;
}

p,
label,
td,
Td {
  word-wrap: break-word;
}

.default-label-text-link {
  color: #374e82 !important;
}

.default-label-text-link:hover {
  color: #5473bb !important;
  cursor: pointer;
}

.filled-in {
  cursor: pointer;
}

.unauthorized-access-message {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-content: center;
  min-height: 84vh !important;
}

.default-pdf-viewer {
  width: 100%;
  min-height: 40vmax;
  height: 84vh;
  border: solid;
}

.divider-color.MuiDivider-root {
  background-color: rgba(56, 53, 226, 0.445) !important;
}

textarea,
p,
strong {
  white-space: pre-line;
}

.pagination-style {
  margin: 0;
  padding: 0;
  color: rgb(33, 37, 41) !important;
  font-size: 12px;
  text-shadow: 0px 0px 1px #343841;
  font-family: 'Montserrat' !important;
}

.dark-blue-text-shadow {
  text-shadow: 1px 2px 1px #1c2842;
}

.component-wrapper-fade-in {
  -webkit-animation: fadein 1s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s;
  /* Firefox < 16 */
  -ms-animation: fadein 1s;
  /* Internet Explorer */
  -o-animation: fadein 1s;
  /* Opera < 12.1 */
  animation: fadein 1s;
}

.component-wrapper-fade-in-large-list {
  -webkit-animation: fadein 1.7s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1.7s;
  /* Firefox < 16 */
  -ms-animation: fadein 1.7s;
  /* Internet Explorer */
  -o-animation: fadein 1.7s;
  /* Opera < 12.1 */
  animation: fadein 1.7s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

a .labelText {
  color: rgb(31, 30, 30);
}

/* @media only screen and (max-width: 768px) {
  #root {
    font-family: Montserrat, sans-serif;
    font-size: calc(2.2vmin + 4px);
    font-weight: 500;
  }

  #footerMainLogoText {
    font-size: calc(2vmin + 4px) !important;
    color: #96949efa;
  }

  .fontSize,
  option,
  .select-field {
    font-size: calc(2.5vmin + 4px) !important;
  }

  #navbarSecond {
    font-size: calc(2.5vmin + 4px) !important;
    min-height: calc(10vh + 2vmin);
    height: 100%;
    border-bottom: 1px solid rgba(204, 204, 204, 0.63);
  }

  #logo {
    min-height: calc(30px + 2vmin) !important;
    height: calc(40px + 4vmin) !important;
  }

  .logoLG {
    min-height: calc(45px + 2vmin) !important;
    height: calc(47px + 4vmin) !important;
  }

  .select-field {
    background-color: #253860 !important;
    color: white !important;
    min-height: 6vh !important;
    margin-bottom: 2vh;
    border-radius: 0% !important;
    display: block !important;
    min-width: 240px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 900px) and (min-height: 1024px) and (max-height: 1280px) {
  .select-field {
    background-color: #253860 !important;
    color: white !important;
    min-height: 6vh !important;
    margin-bottom: 2vh;
    border-radius: 0% !important;
    display: block !important;
    min-width: 650px !important;
  }
}

@media only screen and (max-width: 1280px) {
  #root {
    font-family: Montserrat, sans-serif;
    font-size: 14px !important;
    font-weight: 500;
  }

  #footerMainLogoText {
    font-size: 15px !important;
    color: #96949efa;
  }

  .fontSize,
  option,
  .select-field {
    font-size: 15px !important;
  }

  #navbarSecond {
    font-size: 14px !important;
    min-height: calc(10vh + 2vmin);
    height: 100%;
    border-bottom: 1px solid rgba(204, 204, 204, 0.63);
  }

  #logo {
    min-height: 50px !important;
    height: 50px !important;
  }

  .logoLG {
    min-height: calc(35px + 2vmin) !important;
    height: calc(37px + 4vmin) !important;
  }
} */

.MuiInputBase-root {
  color: #212529;
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 12px !important;
  box-sizing: border-box;
  align-items: center;
  font-family: 'Montserrat' !important;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
}

.MuiTablePagination-caption {
  font-size: 12px !important;
  font-family: 'Montserrat' !important;
}

.modalHeader {
  background-color: #203a5d;
  color: white !important;
}

.labelNoWordBreak {
  width: max-content;
}

.relatedTopicCard {
  background: #dee2e61c;
  border-radius: 5px;
  box-shadow: 1px 3px 10px 1px #adb5bd70;
  padding: 5px;
}


/* .relatedTopicCard:hover {
  transform: scaleY(1.01);
  transition: transform 0.2s ease;
} */

.relatedTopicCard:hover>label {
  transform: scaleY(0.99);
  transition: transform 0.2s ease;
}

.colorGreen {
  color: #68A691;
}

.colorGray {
  color: gray;
}

.colorRed {
  color: #c23b22;
}

.colorOrange {
  color: #ED9121;
}

.methodInProgress {
  background-color: #68A691;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 74px;
}
.methodNotInProgress {
  border: 1px solid #ddd;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 74px;
}

.align-sub {
  vertical-align: sub;
}

.margin-0-auto {
  margin: 0 auto;
}

.finalVersionOfDocument {
  width: 80%;
  margin-left: 10%;
}

.finalVersionOfDocument svg {
  font-size: 30px;
}

.finalVersionOfDocument .open-in-new-icon {
  font-size: 20px;
}

.finalVersionOfDocumentLink {
  display: flex;
  justify-content: center;
}

.finalVersionOfDocumentLink a {
  padding: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.border-bottom-solid {
  border-bottom: 1px solid #5473bb;
}

.position-relative {
  position: relative;
}

.opacity-1 {
  opacity: 1 !important;
}

.w-fit-content {
  width: fit-content;
}

.scroll-80vh {
  overflow: auto;
  max-height: 80vh;
}

.w-100px {
  width: 100px;
}

.w-90percent {
  width: 90%;
}

.min-height-90px {
  min-height: 90px;
}

.min-height-540px {
  min-height: 540px;
}