.search-filter {
  display: block;
  height: 42px;
  min-width: 300px !important;
  width: 100% !important;
  border-radius: 40px;
  box-shadow: 0 1px 10px 0 #eaebf1;
  background-color: #ffffff;
  border: 1px solid rgb(177, 176, 176);
  padding-left: 20px !important;
  padding: 9px;
  outline: none;
}
.search-filter:hover {
  box-shadow: 0 0px 5px 0 #243964dc !important;
  border-color: #24396483 !important;
  outline: none !important;
}

.search-filter-icon {
  position: absolute;
  color: #aaa;
}

#search-filter-img {
  width: 15px;
}
.search-filter-placeholder {
  position: absolute;
  height: 28px;
  width: 89% !important;
  border: none;
  background: none !important;
  margin-left: 25px;
  margin-top: -2px;
}

.search-filter-placeholder:focus {
  outline: none;
}

.search-filter-dashboard {
  display: block;
  height: 42px;
  min-width: 298px !important;
  width: 100% !important;
  border-bottom: 1px solid rgb(177, 176, 176);
  outline: none;
  padding-top: 10px !important;
}
.search-filter-dashboard:hover {
  border-color: #243964dc !important;
  outline: none !important;
}

.search-filter-dashboard-placeholder {
  position: absolute;
  height: 28px;
  width: 82% !important;
  border: none;
  background: none !important;
  margin-left: 20px;
  margin-top: -2px;
}

.search-filter-dashboard-placeholder:focus {
  outline: none !important;
}
