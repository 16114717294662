.question-card-header {
  border-top: 4px solid #203a5d;
  border-bottom: 0;
  background-color: white;
}

.question-edit-card-footer {
  background-color: white;
}

.custom-warning>p {
  margin-top: unset;
  margin-bottom: unset;
}

.surveyQuestionBody {
  max-height: 335px;
  overflow-y: auto;
  position: relative;
}

.surveyQuestionMoreOptions {
  position: sticky;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  color: #203a5d;
  opacity: 1;
  transition: opacity 0.5s ease;
  cursor: pointer;
  font-weight: bold;
}

.surveyQuestionMoreOptions>i {
  vertical-align: middle;
}

.hiddenMoreOptions {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

.surveyQuestionBody input[type="radio"],
.surveyQuestionBody input[type="checkbox"] {
  border-color: #203a5d !important;
}

.progress-bar { 
  background-color: #203a5d;
}

.link:hover {
  cursor: pointer;
}

.fade-in {
  animation: fadeInAnimation 1s linear;
}


@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}