.min-table-height {
  min-height: 40vh !important;
}

.table-header-height {
  height: 8vh !important;
}

.table-header-height th {
  border-top: none !important;
}

.table-header-row {
  background: #697c9d4d !important;
}

.table-row-style,
.table-header-row-style {
  border-bottom: 1px solid rgb(202, 202, 202) !important;
}

.table-row-style:hover {
  font-size: 16px !important;
  background: #697c9d44 !important;
}

.table-row-style td,
.table-header-row-style th {
  padding: 8px 0px !important;
}

.min-dynamic-tabele-height {
  min-height: 20vh !important;
}

/* .fas:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}

.MuiButton-label:hover {
  transform: scale(1.03);
  transition: all 0.2s ease-in-out;
} */
.commentGatheringLink {
  color: #6d90de !important;
  text-decoration: underline !important;
  text-decoration-color: #25396559 !important;
}

.sign-up-column-width {
  min-width: 137px !important;
}

.table-responsive>table>tbody>tr {
  border-style: hidden !important;
}

/*mantine react table custom classes*/
.customTable>thead {
  opacity: 1;
}

.customTable>thead button:hover {
  background-color: transparent;
}

.customTable>thead button:has(svg.tabler-icon-sort-ascending),
.customTable>thead button:has(svg.tabler-icon-sort-descending) {
  background-color: #6d90de;
}

.customTable>thead>tr>th,
.customTable>tbody>tr>td {
  padding: 0.25rem 0.5rem;
}

.customTable .dateColumn .mantine-193qez3 {
  display: unset;
  width: 90%;
}

.customTable .editColumn .mantine-hnifc4 {
  white-space: unset;
}

.customTable .editColumn {
  text-align: center;
}

.customTable>thead>tr>th {
  position: relative;
  height: 100px;
  background-color: #203a5d;
}

.customTable>thead>tr>th>div:nth-child(2) {
  position: absolute;
  bottom: 5px;
  width: 95%
}

.customTable .mantine-MultiSelect-pill {
  background-color: aqua;
}

.customTable>thead .mantine-TableHeadCell-Content-Wrapper,
.customTable>thead button,
.customTable>thead input {
  color: white;
}


.customTable>tbody>tr>td:first-child,
.customTable>thead>tr>th:first-child {
  padding-left: 1rem;
}

.customTable>tbody>tr:nth-child(odd) td {
  background-color: #ced4da5c;
}

.customTable>tbody>tr:nth-child(even) td {
  background-color: white;
}

.customPaper {
  border: none !important;
  box-shadow: none !important;
}

.customPaper>div:last-child {
  background-color: #203a5d;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.customPaper>div:last-child label,
.customPaper>div:last-child button,
.customPaper .mantine-Text-root {
  color: white;
}

.customPaper>div:last-child button:hover {
  background-color: transparent;
}

.customPaper>div:first-child {
  min-height: 2rem;
}


.customPaper>div:first-child>div:first-child {
  padding: 1px;
  background-color: #203a5d;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.customPaper>div:first-child>div:first-child button {
  color: white;
}

.customPaper>div:first-child>div:first-child button:hover {
  background-color: transparent;
}

.customTable .mantine-TableHeadCell-Content {
  justify-content: space-between;
}

.customTable .mantine-TableHeadCell-Content-Labels {
  font-weight: 500;
  width: 100%;
  justify-content: space-between;
}

.customTable .mantine-TableHeadCell-Content-Actions svg {
  /* align-self: flex-start; */
  height: 15px;
}

.customTable .mantine-TableHeadCell-Content-Wrapper {
  flex: 1;
}

.customTable .mantine-MultiSelect-defaultValueRemove {
  color: black;
}

.customTable .topicTitleText {
  color: #6d90de !important;
  text-decoration: underline;
  text-decoration-color: #25396559;
}

.phases a {
  height: 65px;
}

.customTable .mantine-MultiSelect-values {
  width: 100%;
  max-height: 60px;
  overflow-y: auto;
}

.customTable .mantine-MultiSelect-input {
  padding-right: 1.5rem !important;
}

.document-table-div th {
  background-color: #374e82 !important;
  color: white !important;
  font-weight: 500 !important;
}

.document-table-div tbody tr:nth-child(even) td {
  background-color: rgba(220, 220, 220, 0.322);
}

.document-table-div td,
th {
  vertical-align: middle;
}

.document-table-div td p {
  margin-bottom: 0 !important;
}

.document-table-div .only-icon {
  font-size: large;
}

.document-table-div .fa-search {
  font-size: x-large;
}