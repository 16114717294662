.wide-modal .modal-dialog {
  min-width: 98%;
  /* overflow: auto; */
  min-height: 100%;
}

.wide-modal-in-background .modal-dialog {
  min-width: 96vmax;
  overflow: auto;
  min-height: 100%;
}

.wide-modal-in-background {
  z-index: 1000 !important;
}

.secondModal {
  background-color: rgba(0, 0, 0, 0.4) !important;
}
.ocd-desc-padding {
  padding-bottom: 70px;
}

/* 
.modal-in-modal.modal-dialog {
  z-index: 9999 !important;
}

.modal-in-modal .modal-backdrop.show {
  opacity: 0.5;

  z-index: 9988 !important;
} */
.modal-header {
  align-items: flex-start !important;
}

.modal-scroll {
  max-height: calc(100vh - 100px);
  overflow-x: auto;
}

.modal-body-scroll {
  max-height: calc(100vh - 150px);
  overflow-x: auto;
}

.modal-xxl {
  --bs-modal-width: calc(100% - 20px) !important;
}


/*NESTED MODALI*/

/*UNUTRASNJI MODAL*/
div[role="dialog"]:has(> div#addCommentModal),
div[role="dialog"]:has(> div#addDocumentModal),
div[role="dialog"]:has(> div#modalForSpecificCommentInfo),
div[role="dialog"]:has(> div#modalForAddUserOrganization),
div[role="dialog"]:has(> div#modalForUpdateUserOrganization),
div[role="dialog"]:has(> div#modalForOpenAnswersView) {
  background-color: rgba(0, 0, 0, 0.5);
}

/*SPOLJASNJI MODAL*/
div[role="dialog"]:has(> div#modalForTopicOfDiscussionDocuments),
div[role="dialog"]:has(> div#modalForUserOrganizationList),
div[role="dialog"]:has(> div#modalForSurveyResultsView) {
  +.fade.modal-backdrop.show {
    opacity: 0 !important;
  }
}