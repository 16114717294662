.select-field {
  background-color: #253860 !important;
  color: white !important;
  min-height: 55px !important;
  min-width: 300px !important;
  margin-bottom: 2vh;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0px #14243a !important;
  border: none !important;
}
.select-field:hover {
  background-color: #28476d !important;
  /* background-color: #2e3b6b !important; */
}

.select-field-light {
  background-color: #93a3fc !important;
  color: rgb(26, 26, 26) !important;
  min-height: 55px !important;
  min-width: 300px !important;
  margin-bottom: 2vh;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0px #14243a;
  border: none !important;
}

.select-field-light:hover {
  background-color: #a1afff !important;
}

option {
  color: rgb(71, 71, 71);
  background-color: white !important;
  height: 30px;
  break-inside: auto !important;
  word-break: break-all;
}

select {
  background: url(/images/down.png) no-repeat right;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-position-x: 95%;
  padding-right: 10% !important;
}

select:focus-within,
option:focus {
  outline: none !important;
  border: none !important;
}

option:hover {
  border: none !important;
  box-shadow: 0 0 50px 0 !important;
}

.disabled-select-field {
  background-color: #787d88 !important;
  color: white !important;
  min-height: 55px !important;
  min-width: 300px !important;
  margin-bottom: 2vh;
  border-radius: 5px;
  box-shadow: 0px 2px 5px 0px #3b3b3b;
  border: none !important;
}
.disabled-select-field:hover {
  background-color: #6d6d6d !important;
  /* background-color: #2e3b6b !important; */
}

.select-field-for-reports {
  color: #374e82 !important;
  border: none !important;
  font-weight: bolder;
  width: 7em;
}
optgroup {
  background-color: white !important;
  color: #374e82 !important;
}

#selectReport {
  /* padding-right: 22px !important; */
  background: url(/images/down-arrow.png) no-repeat 100% 50% !important;
  background-size: 11px 12px !important;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-position-x: 95%;
}
