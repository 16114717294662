.alert-style,
.warning-alert-style {
  z-index: 99999999 !important;
  position: fixed !important;
  top: 78vh !important;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  vertical-align: middle !important;
  border-radius: 40px !important;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff !important;
  cursor: pointer;
  font-size: 19px;
  display: flex;
  justify-content: center !important;
  height: 60px !important;
  font-weight: normal !important;
}

.alert-style:hover,
.warning-alert-style:hover {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: 0.3s ease-in-out !important;
  color: rgb(223, 0, 0);
}

.alert-style {
  background: #243964f3;
  left: 38vw !important;
}
.alert-style:hover {
  background: #243964 !important;
}

.alert-shown {
  opacity: 1;
  transition: all 650ms linear;
}

.alert-hidden {
  opacity: 0;
  transition: all 650ms linear;
}

.warning-alert-style {
  background: #9c0303f3;
  left: 25vw !important;
}
.warning-alert-style:hover {
  background: #cf0000 !important;
}

/* 
.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
} */
