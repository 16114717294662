.attachment-wrapper,
.pdf-attachment-wrapper {
  position: relative;
  width: 100%;
  min-height: 80vh;
  max-height: 96vh;
  /* margin-right: -2vw; */
  border: 1px solid rgba(128, 128, 128, 0.473) !important;
  overflow: auto;
}

.attachment-wrapper .pg-viewer-wrapper {
  min-height: 80vh !important;
  max-height: 96vh;
  display: contents !important;
}

.attachment-wrapper .pg-viewer-container {
  display: flex;
  flex-flow: column wrap !important;
  min-height: 80vh;
  max-height: 96vh !important;
}

.attachment-wrapper #pg-viewer.pg-viewer {
  position: relative;
  height: 80vh !important;
  margin: auto;
  overflow: auto;
}

.TransformComponent-module_container__3NwNd {
  position: relative;
  width: 100% !important;
  height: fit-content;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
}

.btn {
  position: relative;
  border: 0;
  padding: 15px 25px;
  display: inline-block;
  text-align: center;
  color: white;
}

.tools-button {
  color: gray !important;
}

.pdf-attachment-wrapper {
  position: relative;
  width: 100%;
  min-height: 94vh;
  margin-right: -2vw;
  border: 1px solid rgba(128, 128, 128, 0.479) !important;
}

.pdf-previewer {
  overflow: scroll;
  max-height: 500px;
  margin: auto;
}

.pdf-attachment-wrapper embed {
  position: relative;
  width: 100%;
  min-height: 94vh;
  margin-right: -2vw;
  border: 1px solid rgba(128, 128, 128, 0.479) !important;
  background: white !important;
}

.pg-viewer-wrapper .photo-viewer-container,
#pg-photo-container img {
  height: 100% !important;
  width: 100% !important;
}

#react-doc-viewer>#header-bar {
  display: none;
}

.attachment-wrapper>.element div {
  width: 100%;
}

.docx-viewer-div>iframe {
  width: 100%;
  min-height: 80vh;
  max-height: 96vh;
}