.method-card-passed {
    border-left: 8px solid #d9d9d9;
}
.method-card-ongoing {
    border-left: 8px solid #243964;
}
.method-card-top-heading {
    font-weight: bold;
}

.method-card-middle-heading {
    font-weight: bold;
}

.text-left {
  padding-left: 5px;
}

.decorator-container {
  min-width: 28px;
}

.participated {
  color: #1cb816;
  margin-bottom: 8px;
  margin-left: 6px;
}

.pin {
    margin-top: 10px;
    margin-left: 6px
}
.method-links {
  border: 1px solid #ddd;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 50px;
  /* box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important */
}
.method-links:hover {
  background-color: #ddd;
}
.tableFooterLinks > svg {
  vertical-align: bottom;
  margin-right: 8px;
}
.calendar-icon {
  margin-right: 3px !important;
  padding: 1px !important;
  color: #707071 !important;
  vertical-align: sub !important;
}

svg:active, svg:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}
@media screen and (max-width: 1450px) {
  .top-control-text {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .method-card-header, .method-card-body {
    font-size: small;
  }
  .method-card-header > div > svg {
    display: none;
  }
  
  .method-links {
    padding-right: 8px;
  }
  .method-links > span {
    display: none;
  }
}
.fade-in {
    animation: fadeInAnimation 1s linear;
  }
  
  
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }