.welcomePageMainDiv {
    height: 670px;
    background-image: url(/images/raisingHands-low.png);
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: flex-start;
}

.welcomePageMainDiv>div:first-child {
    margin-left: 4rem;
    margin-top: 2rem;
    display: flex;
    gap: 20px;
}

.welcomePageMainDiv button {
    float: unset;
}

.welcomePageMainDiv p {
    width: 520px;
    font-weight: bold;
    text-align: justify;
    margin-top: 1.5rem;
}

.welcomePageMainDiv h1 {
    font-weight: bolder;
}

.welcomePageMainDiv h1:first-child {
    font-weight: bolder;
    padding-top: 20px;
}

.welcomePageMainDiv img {
    width: 200px;
    height: 160px;
    opacity: 0.5;
}

.welcomePageButtons {
    display: flex;
}

.slick-arrow {
    display: none !important;
}

.carouselWelcomePage {
    width: 100%;
    margin-top: 4rem;
    margin-bottom: 3rem;
}

.carouselWelcomePage .card {
    height: 300px;
    background-color: #ffffff86;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    color: #203a5d;
    margin-left: 8px;
    margin-right: 8px;
}

.carouselWelcomePage .cardHeader {
    height: 80px;
    overflow: hidden;
    display: grid;
    place-items: center;
    color: white;
    background-color: #203a59d9;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 10px;
}

.cardContent .firstRow {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cardContent label {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    text-wrap: nowrap;
}

.cardContent .firstRow label {
    max-width: 95%;
}

.carouselWelcomePage .cardHeader h6 {
    margin-bottom: 0;
    text-align: justify;
    font-size: 1rem;
    text-align: center;
    overflow: hidden;
    max-height: 60px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.cardFooter {
    margin-top: auto;
    padding-bottom: 1rem;
    text-align: center;
}

.cardFooter a {
    display: inline-block;
    border: 1px solid #203a5d;
    height: 35px;
    line-height: 33px;
    box-sizing: border-box;
    padding: 0 20px 0 15px;
    font-weight: 700;
    text-decoration: none;
    color: #203a5d;
    border-radius: 5px;
}

.cardFooter a:hover {
    background-color: #ffffff4f;
}

.cardFooter a::after {
    font-family: 'Font Awesome 5 Free';
    content: '\f061';
    margin-left: 15px;
    vertical-align: middle;
    transform: scaleX(3);
}

.welcomePageMainDiv .carousel-slider {
    padding-bottom: 20px;
}



.cardContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    margin-top: 10px;
    text-align: justify;
}

.secondRow {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.secondRow div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.secondRow>div:first-child>label:nth-child(2)::before {
    font-family: 'Font Awesome 5 Free';
    content: '\f073';
    margin-right: 10px;
    font-weight: bold;
}

.secondRow>div:last-child>label:nth-child(2)::before {
    font-family: 'Font Awesome 5 Free';
    content: '\f15b';
    margin-right: 10px;
    font-weight: bold;
}

.cardContent div label:first-child {
    font-style: italic;
    font-size: 8px;
    text-transform: uppercase;
    padding-right: 10px;
}

.bottomBorder {
    border-bottom: 1px solid #203a5d;
}

.statisticsDiv {
    width: 500px;
    text-align: center;
}

.countUpSpan {
    display: block;
    font-weight: 700;
    font-size: 4rem;
}

.topicOfDiscussionTableFilter {
    height: 3rem;
}

@media screen and (max-width: 760px) {
    .welcomePageMainDiv>div:first-child {
        margin: unset;
    }

    .welcomePageMainDiv p {
        width: auto;
    }

    .carouselWelcomePage {
        margin-top: 1rem;
    }

    .welcomePageButtons {
        font-size: 12px;
    }
}