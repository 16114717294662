.cardPair {
    display: flex;
    height: 100%;
}

.generalPostCard {
    border-radius: 0 !important;
    margin-bottom: 5px;
    border: 0 !important;
    width: 100%;
}
.generalPost {
    margin: 10px;
    border-radius: 0px !important;
}
.generalPostHeader {
    background-color: #243964 !important;
    color: white !important;
    border-radius: 0 !important;
}
.generalPostCardBody { 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 !important;
}
.generalPostBody {
    padding: 0 !important;
}
.generalPostControls {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;
    padding-bottom: 5px;
}
.generalPostControls > a > i {
    padding-right: 5px;
}
.generalPostDescription {
    padding: 10px 15px;
    text-align: left;
}
.generalPostCardHeader {
    color: black !important;
    background-color: white !important;
    text-align: left;
    font-weight: 500;
    border-bottom: 0 !important;
}
.dot {  
    background-color: #243964 !important;
}
.noGeneralPosts {
    display: flex;
    justify-content: center;
    padding-top: 20px;
}
.slider-wrapper {
    margin-bottom: 25px !important;
}

.sectionTitle {
    padding: 8px;
    text-transform: uppercase;
}
.generalPostBody .slick-list:only-child > div {
    width: 100% !important;
}
.generalPostBody .slick-list:only-child > div > div{
    width: 100% !important;
}
.generalPostBody .slick-track {
    display: flex;
}
.generalPostBody .slick-slide {
    height: auto;
    margin-bottom: 10px;
}

.generalPostBody .slick-slide > div{
    height: 100%;
}
.generalPostBody .slick-slide > div > div{
    height: 95%;
}
.generalPostBody .slick-slide > div > div > div{
    height: 100%;
}
