.user-default-icon {
  font-size: 35px;
  color: #6686c0 !important;
}

.user-default-icon:hover,
.user-name-style:hover,
.user-moderator-name-style:hover {
  color: #44639b !important;
  cursor: pointer;
}

.user-name-style {
  color: #2f456d !important;
}

.user-moderator-icon {
  font-size: 35px;
  color: #30466e;
}
.user-moderator-icon:hover {
  color: #112853 !important;
}

.user-moderator-name-style {
  color: #112853 !important;
}


.text {
  transition: opacity 0.5s ease-in-out;
}

.button-read {
  margin-top: 8px; /* Adjust as needed */
  cursor: pointer;
  border: none;
  background: none;
  color: #007bff;
  text-decoration: underline;
  transition: color 0.3s ease-in-out;
}

button:hover {
  color: #0056b3;
}

.text.fade {
  opacity: 0.7; /* Adjust the opacity level as needed */
}