.custom-ui {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: #243964;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}
.custom-ui > button {
  width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 15px;
  transition: 0.3s;
}

.custom-ui > button:hover {
  background: #50679b;
}

.react-confirm-alert-overlay {
  z-index: 1000000 !important;
  background: rgba(255, 255, 255, 0.774) !important;
}

.wide-custom-ui.custom-ui {
  text-align: center;
  width: 700px !important;
  padding: 40px;
  background: #243964;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}
