.dashboardBG {
  background-color: #f6f7f9;
  display: flexbox;
  flex-wrap: wrap;
}

.topicArea {
  min-width: 100% !important;
}

.topicPreviewArea {
  display: flex !important;
  flex-flow: column wrap !important;
  min-width: 420px;
  /* height: 30vmax; */
  /* overflow-y: auto; */
}

.topicTitleText {
  font-size: 17px;
}

.quickPreviewTopicTitleText {
  font-size: 15.5px;
}

.topicTitleText {
  color: #343d5e !important;
}
.topicTitleText:hover,
.quickPreviewTopicTitleText:hover {
  color: #4359aa !important;
}

.quickPreviewTopicTitleText,
.white-link-text {
  color: #203a5d !important;
}

.quickPreviewTopicTitleText,
select,
#addTopicLabel {
  cursor: pointer !important;
}

.quickPreviewArea {
  background-color: #203a5d;
  color: white;
  height: 90vh;
  min-width: 250px !important;
  overflow-y: auto !important;
  min-height: 450px !important;
}

.tabHeader {
  border-bottom: 1px solid #495f88;
  flex: 1;
}

.activeTab,
.active-tab-notification {
  border-bottom: 2px solid #a1b6d3 !important;
}

.tabHeaderTitle {
  display: inline-flex !important;
  text-align: center !important;
  flex-flow: row wrap !important;
  flex: 50%;
  min-width: 100% !important;
}

.combobox-wrapper {
  display: flex !important;
  flex-flow: column wrap !important;
  min-width: 300px;
  padding-left: 6vw !important;
  float: left !important;
}

.active-tab-notification,
.default-tab-notification {
  background: none !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  font-weight: 500;
  font-size: 14px;
}

.active-tab-notification {
  color: #536dcc !important;
}
.default-tab-notification {
  color: #8097ec !important;
}

.active-tab-notification:hover {
  color: #536dcc !important;
}

.default-tab-notification:hover {
  color: #8097ec !important;
  border: none !important;
}

.dashboard .nav-tabs {
  min-width: 30vw !important;
  border-color: #495f88 !important;
}

.subscribe-td {
  min-width: 89px !important;
}

#userActivity.select-field {
  background-color: #28476d !important;
  color: white !important;
  min-height: 55px !important;
  min-width: 300px !important;
  margin-bottom: 2vh;
  border-radius: 5px;
  box-shadow: 0px 1px 0px 0px #32548386 !important;
  border: none !important;
  font-size: 14px;
}
#userActivity.select-field:hover {
  background-color: #28476d !important;
  /* background-color: #2e3b6b !important; */
}

.topic-table-height-dashboard {
  min-height: 34.5vmax;
}

.topicOdDiscussionList-container {
  display: table !important;
  flex-flow: row wrap;
  justify-content: space-evenly !important;
}

.topic-table-height-landing {
  min-height: 34.5vmax;
}

@media only screen and (max-width: 768px) {
  .topicPreviewArea {
    display: flex !important;
    flex-flow: column wrap !important;
    min-width: 300px !important;
    height: 450px !important;
    overflow: auto !important;
  }

  .combobox-wrapper {
    display: flex !important;
    flex-flow: column wrap !important;
    min-width: 300px !important;
    padding-left: 0vw !important;
  }
}

.hrStyle {
  background: #a1b6d3 !important;
}


.signin-button{
  float:right;
}

/* .tab-pane {
  min-height: 280px !important;
  max-height: 50vh !important;
  overflow-y: auto;
  min-width: 29vw !important;
  position: static;
} */

/* @media only screen and (max-width: 768px) {
  .topicTitleText,
  .quickPreviewTopicTitleText {
    font-size: calc(2.5vmin + 4px) !important;
  }

  .quickPreviewArea {
    background-color: #203a5d;
    color: white;
    height: 60vh !important;
    overflow-y: auto !important;
  }

  .topicPreviewArea {
    min-width: 240px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 900px) and (min-height: 1024px) and (max-height: 1280px) {
  .topicArea,
  .quickPreviewArea {
    width: 100%;
    min-width: 100%;
  }

  .topicPreviewArea {
    display: block !important;
    min-width: 650px !important;
  }

  .quickPreviewArea {
    background-color: #203a5d;
    color: white;
    height: 60vh !important;
    overflow-y: auto !important;
  }

  .tabHeader {
    border-bottom: 1px solid #495f88;
    text-align: right !important;
  }
}

@media only screen and (max-width: 1280px) {
  .topicTitleText,
  .quickPreviewTopicTitleText {
    font-size: 15px !important;
  }
} */
