#topic-header-row {
  background-color: #243964;
  min-height: calc(15vh + 6vmin) !important;
  height: 100%;
  color: white;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}

#detailsTable {
  background-color: #8baae633 !important;
  color: white !important;
  font-size: calc(1% + 15px) !important;
  position: relative !important;
  min-height: 260px !important;
}

#detailsTable tbody,
#detailsTable td,
#detailsTable tfoot,
#detailsTable th,
#detailsTable thead,
#detailsTable tr {
  border-width: 1px !important;
  border-style: inset !important;
  border-color: whitesmoke;
}

#detailsTable td {
  padding: unset !important;
  background-color: unset !important;
}

#detailsTable .tdHeader {
  background-color: #243964;
  color: white;
  padding: 0.3em 0.5em 0.3em;
  letter-spacing: 0.5px;
}

#detailsTable tr:nth-child(n+3) td {
  text-align: center;
}


#informationTable td {
  padding: 1em 1em 0.5em;
  margin: 0 !important;
  text-align: justify;
}

#informationTable p {
  min-height: 200px;
  /* height: auto; */
}

#informationTable h5 {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: 0;
}

#informationTable tr:first-child>td {
  color: white;
  background-color: #243964;
}

#informationTable tr:nth-child(2)>td {
  background-color: #8baae633;
  font-weight: bold;
}

#informationTable tr:last-child>td {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}



.topic-description-height {
  min-height: 10em !important;
  /* max-height: 10em !important; */
  overflow-y: auto;
  min-width: 100% !important;
  position: relative;
  margin-bottom: 0% !important;
  padding-bottom: 0% !important;
}

#tableFooter {
  background-color: white;
  color: #374e82 !important;
  border: none !important;
}

.tableFooterLabels {
  color: #374e82 !important;
  font-weight: bold;
}

.tableFooterLinks {
  color: #374e82 !important;
  font-weight: bold;
}

.tableDetailsFooterLabels {
  font-size: 13px;
  font-style: italic;
}

.tableDetailsFooterData {
  letter-spacing: 0.5px;
  font-weight: 500;
  padding: 0.3em 0.5em 0.3em;
  margin-block: 0;
}

.tableFooterCentralized {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.active-tab {
  background-color: #243964;
  color: white !important;
  min-height: 100%;
}

.active-tab:hover {
  background-color: #2f527e;
}

.active-tab-label {
  min-width: 100% !important;
  color: white !important;
  text-shadow: 1px 2px 1px #1c2842;
  letter-spacing: 0.2px;
  background: url(/images/right-white.png) no-repeat right;
  background-position-x: 100%;
  padding-right: 5% !important;
}

.default-tab-style {
  min-height: 100%;
}

.default-tab-style:hover {
  background-color: #5671d431;
  border: solid 1px rgba(189, 189, 189, 0.5) !important;
}

.default-tab-label-style {
  min-width: 100% !important;
  color: #243964 !important;
  /* background: url(/images/right-dark-blue.png) no-repeat right; */
  /* background-position-x: 100%;
  padding-right: 5% !important; */
}

.default-tab-style,
.active-tab {
  padding: 0.5em 0.5em 0.2em 0.9em !important;
}

.active-tab-label,
.default-tab-label-style {
  cursor: pointer !important;
  font-size: calc(1% + 16px) !important;
  margin-bottom: 2px !important;
}

.default-tab-date-label-style {
  color: #657491 !important;
}

.active-tab-date-label {
  color: #bdc3d1 !important;
}

.label-for-add {
  border-bottom: 1px solid rgb(206, 206, 206) !important;
  padding: 0px 12px 0px 12px;
}

.faded-letter-info-paragraph {
  color: rgba(71, 71, 71, 0.712) !important;
  margin-top: 20vh !important;
}

.title-text-color {
  color: #243964;
}

.default-tab-phase-three {
  color: #50679b !important;
  /* margin-left: 2px !important; */
}

.default-tab-phase-three:hover {
  text-shadow: 0 0 0.5px rgba(0, 0, 0, 0.452) !important;
  color: #3d4d72 !important;
}

.active-tab-phase-three {
  color: #243964 !important;
  font-weight: 600 !important;
}

.small-tab-padding {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.small-notification-tab-padding {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.phase-tab {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  background: #f4f4f4;
  min-height: 100%;
  padding: 0.5em 0.5em 0.2em 0.9em !important;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.2s ease;
  transition: margin-top 0.2s ease;
  display: flex;
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.phase-tab>a:hover .default-label-text-link {
  color: #5473bb !important;
}


.phase-tab-empty {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.phase-tab:not(.tab-disabled):not(.active-phase-tab):hover {
  background-color: white;
  margin-top: 5px;

}

.active-phase-tab {
  border-bottom: none !important;
  background: none;
  margin-top: 0;
}

.article77-group {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  border-radius: 10px;
  background-color: #5671d42d;
  box-shadow: 1px 3px 10px 1px rgb(0 0 0 / 8%);
}

.article77-group input {
  width: 20px;
  height: 20px;
  margin-right: 2px;
}

.article77-group label {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
}

.noData {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 10px;
}

.tab-disabled {
  cursor: not-allowed !important;
}

.tab-disabled a,
.tab-disabled label,
.tab-disabled i {
  cursor: not-allowed !important;
}

.tab-disabled:hover {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.tab-disabled>a:hover .default-label-text-link {
  color: #374e82 !important;
}

@media only screen and (min-width:1800px) {
  #informationTable p {
    height: auto;
    min-height: unset;
  }
}

@media only screen and (max-width: 990px) {
  #informationTable p {
    height: auto;
    min-height: unset;
  }
}