.footer {
  display: block;
}

.foot-top {
  position: sticky;
  bottom: -40px;
  background: #253965;
  height: 40px;
  color: #4a90e2;
}

.foot-top.visible {
  bottom: 0;
}

.foot-top .container {
  display: flex;
  min-width: 90%;
}

.foot-top a {
  margin-left: auto;
  height: 35px;
  border-top: 5px solid #4a90e2;
  line-height: 30px;
  padding: 0 20px 0 10px;
  position: relative;
  overflow: hidden;
  font-weight: bold;
  color: #4a90e2;
}

.foot-top a::after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
  width: 15px;
  background: url("/images/totop.svg") no-repeat 0 40%;
}

.foot-top a:hover::after {
  animation-name: scrollTopAnim;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes scrollTopAnim {
  0% {
    top: 0;
    opacity: 1;
  }

  50% {
    top: -60%;
    opacity: 0;
  }

  51% {
    top: 60%;
    opacity: 0;
  }

  100% {
    top: 0;
    opacity: 1;
  }
}

footer {
  /* height: 180px;
    */
  color: #a0a0a0;
}

footer .container {
  display: flex;
  min-width: 90%;
  align-items: center;
}

footer .foot-content {
  flex-grow: 1;
  width: 0;
}

footer .site-title {
  padding: 37px 0 20px 70px;
  background: url("/images/grb.png") no-repeat 0 50% / 50px auto;
}

footer .site-title h3 {
  color: #253965;
  font-size: 2rem;
  font-weight: bold;
}

footer .site-title p {
  color: #4f4f4f;
}

footer .copy {
  font-size: 0.84rem;
  padding: 0 0 10px 70px;
}

footer .copy a {
  color: #253965;
}

footer .copy a:hover {
  color: #000;
  text-decoration: underline;
}

footer .nav {
  font-size: 0.84rem;
  padding: 0 0 30px 70px;
  display: flex;
}

footer .nav li {
  margin-right: 6px;
  padding-right: 6px;
  border-right: 1px solid #a0a0a0;
}

footer .nav li:last-of-type {
  border-right: 0px none;
}

footer .nav li.emph a {
  color: #253965 !important;
}

footer .nav li.emph a:hover {
  color: #253965 !important;
}

footer .nav li a:hover {
  color: #000 !important;
  text-decoration: underline !important;
}

footer .foot-social {
  width: 150px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
}

footer .foot-social li a {
  display: block;
  width: 44px;
  height: 44px;
  border: 2px solid #253965;
  border-radius: 24px;
  overflow: hidden;
  text-indent: -1000px;
  position: relative;
  transition: 0.3s;
}

footer .foot-social li a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.3s;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

footer .foot-social li a:hover {
  background: #253965;
}

footer .foot-social li a:hover:before {
  filter: brightness(0) invert(1);
}

footer .foot-social li.fb a::before {
  background-image: url("/images/ico-facebook.svg");
  background-size: 12px auto;
}

footer .foot-social li.tw a::before {
  background-image: url("/images/ico-twitter.svg");
  background-size: 24px auto;
}

footer .foot-social li.py a::before {
  background-image: url("/images/ico-play.svg");
  background-size: 18px auto;
  background-position: 60% 50%;
}

@media screen and (max-width: 990px) {
  .foot-top .container a {
    margin-right: auto;
  }

  footer .foot-social {
    margin-left: -34px;
  }
}

@media screen and (max-width: 760px) {
  footer .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
  }

  footer .foot-content {
    -webkit-box-flex: 0;
    flex-grow: 0;
    width: auto;
  }

  footer .foot-social {
    padding-top: 48px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
  }

  footer .copy {
    display: none;
  }

  footer .site-title {
    background-position: 50% 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 64px;
    background-size: 30px 54px;
    margin-top: 16px;
    text-align: center !important;
  }

  footer .nav {
    padding: 0;
    padding-bottom: 2vh;
    flex-wrap: wrap;
    -webkit-box-pack: center !important;
    justify-content: center !important;
  }

  footer .nav li {
    margin-bottom: 10px;
  }
}